import {
  AssetType,
  CalculationCountry,
  DkTaxCode,
  Owner,
  Payment,
  PaymentFrequency,
  PensionCoverage,
  Policy,
} from '@dreamplan/autogenerated-clients/calculation';
import { TestProfileWithAnswers } from '@dreamplan/types';
import { sharedDefaultAnswers } from '../sharedProfileAnswers';

export const LarsAndMarianneProfile: TestProfileWithAnswers = {
  name: 'Lars and Marianne',
  answers: [
    ...sharedDefaultAnswers,
    {
      key: 'isUserSelfEmployed',
      value: 'false',
    },
    {
      key: 'userCurrentPensionMonthlyPayment',
      value: 0,
    },
    {
      key: 'spouseCurrentPensionMonthlyPayment',
      value: 1480,
    },
    {
      key: 'userFullName',
      value: 'Lars',
    },
    {
      key: 'userAge',
      value: 1969,
    },
    {
      key: 'hasSpouse',
      value: 'true',
    },
    {
      key: 'spouseName',
      value: 'Marianne',
    },
    {
      key: 'spouseAge',
      value: 55,
    },
    {
      key: 'userMonthlySalary',
      value: 19000,
    },
    {
      key: 'userCurrentPensionSavings',
      value: 5700000,
    },
    {
      key: 'spouseMonthlySalary',
      value: 37000,
    },
    {
      key: 'spouseCurrentPensionSavings',
      value: 1638000,
    },
    {
      key: 'houseAddress',
      value: 'test adresse',
    },
    {
      key: 'houseOwnershipType',
      value: 'Own',
    },
    {
      key: 'houseValue',
      value: 5000000,
    },
    {
      key: 'houseDebt',
      value: 2300000,
    },
    {
      key: 'houseDebtInterest',
      value: 0.02,
    },
    {
      key: 'houseDebtRemainingTenure',
      value: 10,
    },
    {
      key: 'houseDebtHasRepaymentFreeYears',
      value: 'true',
    },
    {
      key: 'houseDebtRemainingRepaymentFreeYears',
      value: 5,
    },
    {
      key: 'userMonthlySavingsAmount',
      value: 0,
    },
    {
      key: 'userCurrentEmergencySavings',
      value: 1900000,
    },
    {
      key: 'fetchPensionInformation',
      value: 'false',
    },
    {
      key: 'hasSecondHouse',
      value: 'false',
    },
    {
      key: 'userName',
      value: 'Lars',
    },
  ],
  expectedPayload: {
    primary: {
      birthYear: 1969,
      pensionAge: 69,
      deathAge: undefined,
    },
    spouse: {
      birthYear: 1970,
      pensionAge: 69,
      deathAge: undefined,
    },
    incomes: [
      {
        id: 'primary-income',
        owner: Owner.Primary,
        payment: {
          type: 'Payment',
          paymentFrequency: PaymentFrequency.Monthly,
          amount: 19000,
        } as Payment,
      },
      {
        id: 'spouse-income',
        owner: Owner.Spouse,
        payment: {
          type: 'Payment',
          paymentFrequency: PaymentFrequency.Monthly,
          amount: 37000,
        } as Payment,
      },
    ],
    policies: [
      {
        id: 'dd5f84da-066d-4610-8753-4d4e9fc8960p',
        owner: Owner.Primary,
        coverages: [
          {
            type: 'PensionCoverage',
            id: 'dd5f84da-066d-4610-8753-4d4e9fc8960p',
            owner: Owner.Primary,
            initialValue: 5700000,
            taxCode: DkTaxCode.One,
            contribution: {
              type: 'PensionContribution',
              paymentFrequency: PaymentFrequency.Monthly,
              amount: 0,
              isFromEmployer: undefined,
            },
            payOutType: {
              type: 'LifeAnnuity',
              payOutDate: undefined,
            },
          } as PensionCoverage,
        ],
      } as Policy,
      {
        id: 'dd5f84da-066d-4610-8753-4d4e9fc896sp',
        owner: Owner.Spouse,
        coverages: [
          {
            type: 'PensionCoverage',
            id: 'dd5f84da-066d-4610-8753-4d4e9fc896sp',
            owner: Owner.Spouse,
            initialValue: 1638000,
            taxCode: DkTaxCode.One,
            contribution: {
              type: 'PensionContribution',
              paymentFrequency: PaymentFrequency.Monthly,
              amount: 1480,
            },
            payOutType: {
              type: 'LifeAnnuity',
            },
          } as PensionCoverage,
        ],
      } as Policy,
    ],
    housesWithLoans: [
      {
        id: 'dd5f84da-066d-4610-8753-4d4e9fc8960f',
        houseValue: 5000000,
        type: 'HouseWithLoans',
        loans: [
          {
            id: 'd9497ef5-9d8a-4c03-8308-cbee6746339c',
            remainingPrincipal: 2300000,
            type: 'AmortizedLoan',
            contributionFeeRate: 0.006999999999999999,
            interestRate: 0.0002,
            redemptionDetails: {
              // bondPrice: 0,
              redemptionFee: 5000,
            },
            remainingRepaymentFreeYears: 5,
            remainingTenure: 10,
          },
        ],
      },
    ],
    liquidAssets: [
      {
        id: 'dd5f84da-066d-4610-8753-4d4e9fc8960h',
        assetType: AssetType.Stocks,
        initialValue: 1900000,
        monthlyDeposit: undefined,
      },
    ],
    municipalityId: undefined,
    companies: undefined,
    dreams: undefined,
    calculationCountry: CalculationCountry.Dk,
    recommendationSettings: {
      loanRecommendation: {
        type: 'RestrictedLoanToValueRatioGoalSeek',
        establishmentDetails: {
          foundationFee: 20000,
          // bondPrice: 98,
        },
        // interestRate: 0.06,
        // contributionFeeRate: 0.02,
      },
    },
  },
  expectedResult: undefined,
};
